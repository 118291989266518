import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Handstand Holds, Walks `}<em parentName="p">{`(:30 of practice)`}</em></p>
    <p>{`then,`}</p>
    <p>{`4 Rounds, not timed, of:`}</p>
    <p>{`:30 Handstand Hold`}</p>
    <p>{`1:00 Plank`}</p>
    <p>{`30 Full Crunches`}</p>
    <p>{`20 Back Extensions`}</p>
    <p>{`15 V Ups`}</p>
    <p>{`10/side Med Ball Rotations (w/feet off ground)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`If you haven’t already please find the Crossfittheville Facebook
page and the private group created for Ville members.  We will be
sending at home workouts through this group.  If you cannot get on
Facebook please let us know and we will email them to you.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      